import React, {
  createContext,
  useContext,
  useRef,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Tour, message } from "antd";

import constants from "../config/constants";
import { getStorage, setStorage } from "../helpers";

export const TourContext = createContext();

export const TourProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);

  const user = useSelector((state) => state.auth.user);
  const selectedSource = useSelector((state) => state.app.selectedSource);
  const sources = useSelector((state) => state.source.sources);
  const messages = useSelector((state) => state.message.messages || []);

  const steps = [
    {
      title: `Hi ${user?.name}`,
      description:
        "Welcome to TheStockGenius.ai - The easiest way to analyze stocks like a professional trader!",
      cover: (
        <img
          className="!w-[80px] mt-[-100px] px-4 py-3 rounded-full bg-green-800 border-solid border-4 border-white"
          alt="user.png"
          src="/imgs/guider.png"
        />
      ),
      target: null,
      placement: "center",
    },
    {
      title:
        "These are the popular questions, click one of them and the AI will begin to analyze & answer your question.",
      // description: "Put your files here.",
      cover: (
        <img
          className="!w-[80px] mt-[-100px] px-4 py-3 rounded-full bg-green-800 border-solid border-4 border-white"
          alt="tour.png"
          src="/imgs/guider.png"
        />
      ),
      target: () => ref1.current,
      placement: "right",
    },
    {
      title: "You can also ask your own custom questions here",
      // description: "Save your changes.",
      cover: (
        <img
          className="!w-[80px] mt-[-100px] px-4 py-3 rounded-full bg-green-800 border-solid border-4 border-white"
          alt="tour.png"
          src="/imgs/guider.png"
        />
      ),
      target: () => ref2.current,
      placement: "top",
    },
    {
      title:
        "Search your favorite stocks or symbols here to begin asking them questions",
      // description: "Click to see other actions.",
      cover: (
        <img
          className="!w-[80px] mt-[-100px] px-4 py-3 rounded-full bg-green-800 border-solid border-4 border-white"
          alt="tour.png"
          src="/imgs/guider.png"
        />
      ),
      target: () => ref3.current,
      placement: "bottomRight",
      nextButtonProps: {
        // children: <>Finish</>,
        onClick: () => setStorage("skipTour", "1"),
      },
    },
    // {
    //   title: "You can get answer of this question in few minutes",
    //   // description: "Click to see other actions.",
    //   cover: (
    //     <img
    //       className="!w-[80px] mt-[-100px] px-4 py-3 rounded-full bg-green-800 border-solid border-4 border-white"
    //       alt="tour.png"
    //       src="/imgs/guider.png"
    //     />
    //   ),
    //   target: () => ref4.current,
    //   placement: "bottomRight",
    //   nextButtonProps: {
    //     children: <>Finish</>,
    //     onClick: () => setStorage("skipTour", "1"),
    //   },
    // },
  ];

  useEffect(() => {
    // console.log(selectedSource.split("_")[3], sources.length, messages.l);
    if (
      selectedSource.split("_")[3] === "TSLA" &&
      sources.length > 0 &&
      messages.length > 0 &&
      messages.length <= 2 &&
      getStorage("skipTour") !== "1"
    ) {
      setOpen(true);
    }
  }, [sources, selectedSource, messages]);

  return (
    <TourContext.Provider value={[ref1, ref2, ref3, ref4, open, setOpen]}>
      {children}
      <Tour
        className="!max-w-[100px]"
        open={open}
        onClose={() => setOpen(false)}
        steps={steps}
        indicatorsRender={(current, total) => (
          <>
            {current + 1 !== total && (
              <Button
                className="mr-2"
                type="primary"
                danger
                size="small"
                onClick={() => { setStorage("skipTour", "1");  setOpen(false)}}
              >
                Skip
              </Button>
            )}
            <span className="text-center">
              {current + 1} / {total}
            </span>
          </>
        )}
      />
    </TourContext.Provider>
  );
};

export const useTour = () => useContext(TourContext);
