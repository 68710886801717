const themeOverrides = {
  token: {
    colorPrimary: "#005C1B",
  },
  components: {
    Button: {
      // colorPrimary: "#273E52",
    },
    Layout: {
      colorBgHeader: "#005C1B",
    },
  },
}

export default themeOverrides;