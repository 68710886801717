import React from "react";
import { useSelector } from "react-redux";
import {
  Navigate,
  Outlet,
  useLocation,
} from 'react-router-dom';

function PublicRoute() {
  const location = useLocation();
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  let to = location.state?.from || '/home';
  return !isAuthenticated ? <Outlet /> : <Navigate to={to} />
}

export default PublicRoute;