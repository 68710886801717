import { createSlice } from "@reduxjs/toolkit";
import { getStorage, setStorage } from "../../helpers";

const initialState = {
    selectedSource: getStorage("latestKey") || '',
    exQuiz: "",
    openKeys: getStorage('openKeys')?.split(',') || [],
    usersCount: getStorage('usersCount') || 4632,
};

const appSlice = createSlice({
    name: "app",
    initialState,
    reducers: {
        setSelectedSource(state, action) {
            if(action.payload.sourceId && action.payload.sourceId !== 'loading') {
                setStorage('latestKey', action.payload.sourceId);
                state.selectedSource = action.payload.sourceId;
            }
        },
        setExQuiz(state, action) {
            state.exQuiz = action.payload;
        },
        setOpenKeys(state, action) {
            setStorage('openKeys', action.payload.join(','));
            state.openKeys = action.payload;
        },
        setUsersCount(state, action) {
            setStorage('usersCount', action.payload);
            state.usersCount = action.payload;
        }
    },
});

export const { setSelectedSource, setExQuiz, setOpenKeys, setUsersCount } = appSlice.actions;

export default appSlice.reducer;
