const constants = {
  HOST_URL: process.env.REACT_APP_HOST_URL || '/api/',
  // HOST_URL: process.env.REACT_APP_HOST_URL || 'http://46.175.146.14:5000/api/',
  // HOST_URL: process.env.REACT_APP_HOST_URL || 'http://192.168.108.121:5000/api/',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  multipartHeaders: {
    "Content-Type": "multipart/form-data",
    Accept: 'multipart/form-data',
  },
  stripePK: 'pk_live_51NSMkhHf40UCzHlZEsyEQG7jpi9jYXD1Go1mjIMalAVCYFMkfyqpt7C7togvCK128Llb3VZE9EFHTO19kXlSf6rt005MkGosS0',
  // stripePK: 'pk_test_51MqDHeIhTKltFnGq3WGCHjZi5ZfUg5T8aiGVPa7XFZcrse4SGLvTjDbXDNb5inKjlOaJqHJRDv5HsPQ6tbnfsxRL00rwetwKzu',
  SOCKET_URL: '/',
  // SOCKET_URL: 'http://46.175.146.14:5000/',
  // SOCKET_URL: 'http://192.168.108.121:5000/',
};

export default constants;
