import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";

const initialState = {
  sources: [],
  loading: false,
  error: {},
  uploading: false,
  uploadingError: {},
  deleting: false,
  deletingError: {},
  renaming: false,
  renamingError: {},
};

const sourceSlice = createSlice({
  name: "source",
  initialState,
  reducers: {
    getSources(state) {
      state.loading = true;
      state.error = {};
    },
    getSourcesSuccess(state, action) {
      state.loading = false;
      state.sources = action.payload.sources;
    },
    getSourcesFailure(state, action) {
      state.loading = false;
      state.sources = [];
      state.error = action.payload.error;
    },
    addSource(state, action) {
      state.uploading = true;
      state.uploadingError = {};
      state.sources = [
        ...state.sources,
        { sourceId: "loading", name: action.payload.sourceName },
      ];
    },
    addSourceSuccess(state, action) {
      state.uploading = false;
      const { documents, ...rest } = action.payload;
      if (rest.sourceId) {
        state.sources = state.sources.filter((so) => so.sourceId !== "loading");
        state.sources = [...state.sources, rest];
        message.success("Uploaded property successfully!");
      }
    },
    updateSource(state, action) {
      const { source } = action.payload;
      if (source) {
        let index = state.sources.findIndex((so) => so._id === source._id);
        if (index >= 0) {
          state.sources.splice(index, 1, source);
        }
      }
    },
    addSourceFailure(state, action) {
      state.uploading = false;
      state.sources = state.sources.filter((so) => so.sourceId !== "loading");
      state.uploadingError = action.payload;
      if (state.uploadingError.response?.data?.message) {
        message.error(state.uploadingError.response?.data?.message);
      } else {
        message.error("Oops! Failed to add property.");
      }
    },
    deleteSource(state) {
      state.deleting = true;
      state.deletingError = {};
    },
    deleteSourceSuccess(state, action) {
      state.deleting = false;
      state.sources = state.sources.filter(
        ({ sourceId }) => sourceId != action.payload.sourceId
      );
      message.success("Deleted property");
    },
    deleteSourceFailure(state, action) {
      state.deleting = false;
      state.error = action.payload.error;
      message.error("Failed to delete property.");
    },
    renameSource(state) {
      state.renaming = true;
      state.renamingError = {};
    },
    renameSourceSuccess(state, action) {
      state.renaming = false;
      const { sourceId, name } = action.payload;
      state.sources = state.sources.map((source) => {
        if (source.sourceId === sourceId) {
          source.name = name;
          return source;
        }
        return source;
      });
    },
    renameSourceFailure(state, action) {
      state.renaming = false;
      state.renamingError = action.payload.error;
    },
  },
});

export const {
  getSources,
  getSourcesSuccess,
  getSourcesFailure,
  addSource,
  addSourceSuccess,
  addSourceFailure,
  deleteSource,
  deleteSourceSuccess,
  deleteSourceFailure,
  renameSource,
  renameSourceSuccess,
  renameSourceFailure,
  updateSource,
} = sourceSlice.actions;

export default sourceSlice.reducer;
