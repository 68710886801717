import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { getStorage } from "../../helpers";

const initialState = {
  messages: [],
  loading: [],
  error: {},
  // states for prompt
  waiting: false,
  promptError: {},
  // states for delete functionality
  reseting: false,
  resetingError: {},
};

const messageSlice = createSlice({
  name: "message",
  initialState,
  reducers: {
    getMessages(state, action) {
      if (!state.loading.includes(action.payload.sourceId)) {
        state.loading = [...state.loading, action.payload.sourceId];
      }
      state.error = {};
    },
    getMessagesSuccess(state, action) {
      let selectedSource = getStorage("latestKey") || "";
      if (selectedSource.split("_")[2] === action.payload.sourceId) {
        state.messages = action.payload.messages;
      }
      state.loading = state.loading.filter(
        (value) => value !== action.payload.sourceId
      );
      // console.log(action.payload.sourceId, state.loading, '--------------');
    },
    getMessagesFailure(state, action) {
      state.loading = state.loading.filter(
        (value) => value !== action.payload.sourceId
      );
      state.messages = [];
      state.error = action.payload.error;
    },
    deleteMessages(state) {
      state.reseting = true;
      state.resetingError = {};
    },
    deleteMessagesSuccess(state) {
      state.reseting = false;
      state.messages = [];
      message.success("Removed all messages.");
    },
    deleteMessagesFailure(state, action) {
      state.reseting = false;
      state.resetingError = action.payload.error;
    },
    sendMessage(state, action) {
      state.messages = [...(state.messages || []), action.payload];
      state.waiting = action.payload.sourceId;
      state.promptError = {};
    },
    sendMessageSuccess(state, action) {
      state.waiting = false;
      let selectedSource = getStorage("latestKey") || "";
      if (selectedSource.split("_")[2] === action.payload.sourceId) {
        state.messages = [
          ...state.messages,
          { ...action.payload.apiMessage, stream: true },
        ];
      }
    },
    sendMessageFailure(state, action) {
      state.waiting = false;
      state.promptError = action.payload.error;
    },
  },
});

export const {
  getMessages,
  getMessagesSuccess,
  getMessagesFailure,
  deleteMessages,
  deleteMessagesSuccess,
  deleteMessagesFailure,
  sendMessage,
  sendMessageSuccess,
  sendMessageFailure,
} = messageSlice.actions;

export default messageSlice.reducer;
